import {
  ICreateOrganisationSubscriptionRequest,
  IPayOrganisationSubscriptionRequest,
  ISubscriptionPriceRequest
} from 'data-transfers/requests'
import {
  IAvailableSubscriptionsResponse,
  IChangeSubscriptionPlanResponse,
  IInitiatePaymentResponse,
  IOrganisationSubscriptionResponse,
  ISubscriptionPriceResponse
} from 'data-transfers/responses'

import { authClient, publicClient } from 'utils'

export const getOrganisationSubscriptions = async () => {
  const { data } = await authClient.get<IOrganisationSubscriptionResponse>(
    `/subscriptions/my`
  )
  return data
}

export const getAvailableSubscriptions = async () => {
  const { data } = await publicClient.get<IAvailableSubscriptionsResponse>(
    `/subscriptions`
  )
  return data
}

export const getSubscriptionsPrice = async ({
  subscriptionId
}: ISubscriptionPriceRequest) => {
  const { data } = await authClient.get<ISubscriptionPriceResponse>(
    `/subscriptions/price/${subscriptionId}`
  )
  return data
}

export const createOrganisationSubscription = async (
  payload: ICreateOrganisationSubscriptionRequest
) => {
  const { data } = await authClient.post<IChangeSubscriptionPlanResponse>(
    `/subscriptions/my`,
    payload
  )
  return data
}

export const payForSubscription = async ({
  subscriptionId,
  ...payload
}: IPayOrganisationSubscriptionRequest) => {
  const { data } = await authClient.patch<IInitiatePaymentResponse>(
    `/payments/subscriptions/${subscriptionId}`,
    payload
  )
  return data
}

export const cancelOrganisationSubscription = async () => {
  const { data } = await authClient.post<void>(`/subscriptions/unsubscribe`)
  return data
}

export const rollbackOrganisationSubscription = async () => {
  const { data } = await authClient.post<void>(`/subscriptions/subscribe`)
  return data
}
