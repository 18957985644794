import Image from 'next/image'
import { useSession } from 'next-auth/react'
import { useTranslation } from 'next-i18next'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { toast } from 'react-toastify'
import { IOrganisation } from 'data-transfers/dto'
import { OrganisationOnboardingStatus } from 'enums'
import { Form, Formik } from 'formik'
import { toFormikValidationSchema } from 'zod-formik-adapter'

import AccordionItem from 'components/AccordionItem'
import { Button } from 'components/buttons'
import Dropzone from 'components/Dropzone'
import Input from 'components/Fields/Input'
import ModalContent from 'components/modals/ModalContent'
import { IconArrow, IconClose, IconEdit, IconYoutube } from 'icons'

import useOrgNewStore from 'store/useOrgNewStore'
import { postImage } from 'hooks/useImage'
import { useSaveOrganisationStep } from 'hooks/useOrganisation'

import {
  orgStep7Validator,
  youtubeUrlValidator
} from 'validators/orgStepValidator'
import { getZodMessage } from 'utils/validation.util'

import { IOrgStepProps } from './types'

type IFormContent = Pick<IOrganisation, 'gallery'>

const IMAGE_AMOUNT = 4

const getYoutubeCode = (src?: string | null) => {
  if (!src) return undefined
  const match = String(src).match(
    /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w-]+\?v=|embed\/|v\/)?)([\w-]+)(\S+)?$/
  )
  if (!match) return undefined
  return match[6] || undefined
}

const YoutubeThumbnail = ({
  src,
  className
}: {
  src: string | null
  className?: string
}) => {
  const code = getYoutubeCode(src)
  if (!code) return null
  return (
    <>
      <Image
        className={className}
        src={`https://img.youtube.com/vi/${code}/1.jpg`}
        alt="youtube preview"
        layout="fill"
        objectFit="cover"
        objectPosition="center"
      />
      <div className="absolute top-0 left-0 flex h-full w-full items-center justify-center bg-black text-white opacity-0 transition-all hover:opacity-50">
        <IconEdit className="h-8 w-8" />
      </div>
    </>
  )
}
export const FormYoutube = ({
  initialValues = { youtubeUrl: '' },
  footer,
  onSubmit
}: IOrgStepProps<{ youtubeUrl: string }>) => {
  const { t } = useTranslation(['org_form', 'actions'])

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={toFormikValidationSchema(youtubeUrlValidator)}
      onSubmit={({ youtubeUrl }) => {
        if (youtubeUrl) {
          onSubmit?.({ youtubeUrl })
        }
      }}
    >
      {({ values }) => (
        <Form>
          <div className={'mb-5'}>
            <Input
              label={t('galleryForm.video.label', {
                ns: 'org_form'
              })}
              placeholder={t('galleryForm.video.placeholder', {
                ns: 'org_form'
              })}
              name="youtubeUrl"
            />
            <div className="relative mt-5">
              <iframe
                src={
                  getYoutubeCode(values.youtubeUrl)
                    ? `https://www.youtube.com/embed/${getYoutubeCode(
                        values.youtubeUrl
                      )}?controls=0`
                    : undefined
                }
                className="aspect-video h-[200px] sm:h-[280px] md:h-[360px]"
                title="Company Video"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          </div>
          {footer || (
            <div className="flex justify-end gap-x-4">
              <Button className="animated-svg" type="submit">
                <span className=" mr-2">{t('next', { ns: 'actions' })}</span>
                <IconArrow />
              </Button>
            </div>
          )}
        </Form>
      )}
    </Formik>
  )
}
export const FormCompanyGallery = ({
  initialValues,
  footer,
  onSubmit
}: IOrgStepProps<IFormContent>) => {
  const { t } = useTranslation(['org_form', 'actions'])
  const incStep = useOrgNewStore((state) => state.incStep)
  const decStep = useOrgNewStore((state) => state.decStep)
  const tempOrg = useOrgNewStore((state) => state.tempOrg)

  const majorStep = useOrgNewStore((state) => state.majorStep)
  const setMajorStep = useOrgNewStore((state) => state.setMajorStep)

  const { data: session } = useSession()
  const jwt = session?.jwt

  const [gallery, setGallery] = useState<(File | string)[]>([])
  const [isOpenModal, setIsOpenModal] = useState(false)
  const currentGallery = useMemo<IFormContent>(() => {
    const selectedGallery = initialValues
      ? initialValues.gallery
      : tempOrg?.gallery
    return { gallery: selectedGallery ?? [] }
  }, [initialValues, tempOrg?.gallery])

  const [youtubeUrl, setYoutubeUrl] = useState<string | null>(null)
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': []
    },
    onDrop: async (acceptedFiles) => {
      if (acceptedFiles.length + gallery.length > IMAGE_AMOUNT) {
        toast(
          t('toast.imageNotAdded', {
            count: IMAGE_AMOUNT,
            ns: 'actions'
          })
        )
      } else {
        const value = [...gallery, ...acceptedFiles]
        setGallery(value)
      }
    }
  })
  const onYouTubeUrlChange = useCallback(
    ({ youtubeUrl }: { youtubeUrl: string }) => {
      setYoutubeUrl(youtubeUrl)
      setIsOpenModal(false)
    },
    []
  )

  useEffect(() => {
    const { youtubeUrl, images } = currentGallery.gallery.reduce<{
      youtubeUrl?: string
      images: string[]
    }>(
      (acc, url) => {
        if (youtubeUrlValidator.safeParse(url).success) {
          acc.youtubeUrl = url
          return acc
        }
        acc.images.push(url)
        return acc
      },
      { images: [] }
    )

    if (youtubeUrl) {
      setYoutubeUrl(youtubeUrl)
    }
    setGallery(images)
  }, [currentGallery?.gallery])

  const { mutate, isLoading } = useSaveOrganisationStep(
    OrganisationOnboardingStatus.Gallery,
    {
      onSuccess: () => {
        incStep(OrganisationOnboardingStatus.PrivacyPolicy)
        setMajorStep(majorStep + 1)
      }
    }
  )

  return (
    <Formik
      initialValues={{
        gallery: currentGallery?.gallery
      }}
      enableReinitialize={true}
      validationSchema={toFormikValidationSchema(orgStep7Validator)}
      onSubmit={async (_v, helper) => {
        try {
          if (!jwt) return
          const promises = gallery.map((file) => {
            if (typeof file === 'string') {
              return file
            }
            return postImage({ file, jwt })
          })
          const images = await Promise.all(promises)
          const newGallery = [...images, youtubeUrl].filter(
            (item) => typeof item === 'string'
          ) as string[]

          mutate({ data: { gallery: newGallery } })

          if (onSubmit) {
            onSubmit({
              gallery: newGallery
            })
            return
          }
        } catch (error) {
          toast(
            t('toast.errorUpdatingImages', {
              ns: 'actions'
            })
          )
          helper.setErrors({
            gallery: t('toast.errorUpdatingImages', {
              ns: 'actions'
            })
          })
        }
      }}
    >
      {(props) => (
        <Form>
          <div className="my-5">
            <Dropzone
              getRootProps={getRootProps}
              getInputProps={getInputProps}
              width={-1}
              className="h-40 w-full outline-none"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="h-12 w-12"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
                />
              </svg>
              <span className="block">
                {t('galleryForm.description', { ns: 'org_form' })}
              </span>
            </Dropzone>
            <div className="mt-5 flex flex-wrap gap-5">
              {gallery.map((file: File | string, index) => (
                <div
                  key={typeof file === 'string' ? file : file.name + index}
                  onClick={() => {
                    setGallery([
                      ...gallery.slice(0, index),
                      ...gallery.slice(index + 1)
                    ])
                  }}
                  className="group relative flex aspect-square w-20 cursor-pointer items-center justify-center rounded border border-black outline-none transition-colors "
                >
                  <Image
                    src={
                      typeof file === 'string'
                        ? file
                        : URL.createObjectURL(file)
                    }
                    alt={typeof file === 'string' ? file : file.name}
                    layout="fill"
                    objectFit="cover"
                    objectPosition="center"
                    className="z-10"
                  />
                  <div className="absolute top-0 left-0 z-20 flex h-full w-full flex-col items-center justify-center overflow-hidden break-all bg-black p-1 text-center text-xs text-white opacity-0 transition-opacity group-hover:opacity-80 group-active:opacity-60">
                    <div className="mx-auto w-fit rounded-full border-2 border-white ">
                      <IconClose className="h-6 w-6 p-1" />
                    </div>
                  </div>
                </div>
              ))}
              {new Array(IMAGE_AMOUNT - gallery.length)
                .fill(0)
                .map((i, index) => (
                  <div
                    key={index}
                    {...getRootProps()}
                    className="flex aspect-square w-20 cursor-pointer items-center justify-center rounded border-2 border-dashed border-black outline-none transition-colors hover:border-red-550 active:border-red-550"
                  ></div>
                ))}
              {youtubeUrl ? (
                <div
                  onClick={() => setIsOpenModal(true)}
                  className="group relative flex aspect-square w-20 cursor-pointer items-center justify-center rounded border border-black outline-none transition-colors"
                >
                  <YoutubeThumbnail src={youtubeUrl} />
                </div>
              ) : (
                <div
                  onClick={() => setIsOpenModal(true)}
                  className="flex aspect-square w-20 cursor-pointer flex-col items-center justify-center rounded border-2 border-dashed border-black text-gray-400 outline-none transition-colors hover:border-red-550 hover:text-gray-500 active:border-red-550 active:text-gray-600"
                >
                  <IconYoutube className="h-10 w-10" />
                  <span className="text-sm uppercase">
                    {t('add', { ns: 'actions' })}
                  </span>
                </div>
              )}
            </div>
          </div>
          {footer || (
            <div className="flex flex-wrap justify-between gap-4">
              <Button
                onClick={() => decStep(OrganisationOnboardingStatus.Photo)}
              >
                <span>{t('back', { ns: 'actions' })}</span>
              </Button>
              <div className="flex items-center justify-end gap-4">
                {typeof props.errors.gallery === 'string' && (
                  <span className="fade-in font-medium tracking-wide text-error-400">
                    {getZodMessage(
                      props.errors.gallery,
                      t('zod:' + props.errors.gallery)
                    )}
                  </span>
                )}
                <Button
                  className="animated-svg"
                  type="submit"
                  isLoading={isLoading}
                >
                  <span className=" mr-2">{t('next', { ns: 'actions' })}</span>
                  <IconArrow />
                </Button>
              </div>
            </div>
          )}

          <ModalContent
            isOpen={isOpenModal}
            onClose={() => setIsOpenModal(false)}
            title={t('galleryForm.video.title', { ns: 'org_form' })}
          >
            <FormYoutube
              onSubmit={onYouTubeUrlChange}
              footer={
                <div className="flex w-full justify-end gap-x-5">
                  <Button onClick={() => setIsOpenModal(false)}>
                    <span className=" mr-2">
                      {t('cancel', { ns: 'actions' })}
                    </span>
                  </Button>
                  <Button className="animated-svg" type="submit">
                    <span className=" mr-2">
                      {t('save', { ns: 'actions' })}
                    </span>
                    <IconArrow />
                  </Button>
                </div>
              }
            />
          </ModalContent>
        </Form>
      )}
    </Formik>
  )
}

const OrgStep7 = ({ index, step }: { index: number; step?: string }) => {
  const { t } = useTranslation(['org_form'])
  const activeStep = useOrgNewStore((state) => state.activeStep)
  const setStep = useOrgNewStore((state) => state.setStep)
  const passedSteps = useOrgNewStore((state) => state.passedSteps)
  return (
    <AccordionItem
      passedSteps={passedSteps}
      index={index}
      isOpen={activeStep === index}
      isCheck={index < activeStep}
      setStep={setStep}
      title={step}
      description={t('companyGallery.description', { ns: 'org_form' })}
    >
      <FormCompanyGallery />
    </AccordionItem>
  )
}

export default OrgStep7
