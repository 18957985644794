import { EmploymentStatus } from 'enums'
import { z } from 'zod'

import {
  ALL_DEGREE_ID,
  ALL_FIELD_OF_STUDY_ID
} from 'components/Fields/SelectListItem'

import checkUtil from 'utils/check.util'

import { advertAddressValidator } from './addressValidator'
import { adDescriptionValidator } from './descriptionValidator'
import educationValidator from './educationValidator'
import translationValidator from './fieldLanguageValidator'
import { languageLevelValidator } from './languageValidator'

export const advertInformationValidator = z.object({
  hiringForClient: z.boolean(),
  positionTexts: translationValidator.array(),
  positionId: z.string().uuid().nullable(),
  positionLevelId: z
    .string({
      invalid_type_error: 'custom.required'
    })
    .uuid(),
  businessFunctionId: z
    .string({ invalid_type_error: 'custom.required' })
    .uuid(),
  industryId: z.string({ invalid_type_error: 'custom.required' }).uuid(),
  address: advertAddressValidator.nullable(),
  titles: translationValidator.extend({ value: z.string().nullable() }).array()
})
export const adStep0Validator = advertInformationValidator
  .passthrough()
  .transform((val) => {
    const positionId = val.positionTexts.find((x) =>
      checkUtil.isUUID(x.value)
    )?.value
    if (positionId) {
      val.positionId = positionId
      val.positionTexts = []
    } else {
      val.positionId = null
    }

    return val
  })
  .refine(
    (value) => {
      if (!value.positionId && !value.positionTexts.length) {
        return false
      }
      return true
    },
    { message: 'custom.required', path: ['positionId'] }
  )

export const advertSkillsValidator = z.object({
  drivingLicence: z.boolean().nullable(),
  competencies: z
    .array(
      z.object({
        skillId: z.string().optional(),
        yearsOfExperience: z.number().optional(),
        priority: z.enum(['Mandatory', 'Optional'])
      })
    )
    .min(1, 'custom.select1Skill')
})
export const adStep1Validator = advertSkillsValidator.superRefine(
  (obj, ctx) => {
    // Do not use duplicate skillId
    const skillIds = obj.competencies.map((c) => c.skillId).filter((id) => id)

    if (!skillIds.length) {
      ctx.addIssue({
        path: ['competencies'],
        code: z.ZodIssueCode.custom,
        message: 'custom.select1Skill'
      })
    }

    const uniqueSkillIds = new Set(skillIds)
    if (skillIds.length !== uniqueSkillIds.size) {
      ctx.addIssue({
        path: ['competencies'],
        code: z.ZodIssueCode.custom,
        message: 'custom.noDuplicateSkills'
      })
    }
  }
)

export const advertEducationValidator = z.object({
  relevantEducation: z.boolean().default(false),
  educations: educationValidator.array().min(0)
})

export const adStepEducationValidator = advertEducationValidator.superRefine(
  (obj, ctx) => {
    const educations = obj.educations.filter(
      (item) => !!item.educationFieldOfStudyId || !!item.educationDegreeId
    )

    if (
      obj.relevantEducation &&
      obj.educations.find(
        (e) =>
          e.educationFieldOfStudyId === ALL_FIELD_OF_STUDY_ID &&
          e.educationDegreeId === ALL_DEGREE_ID
      )
    ) {
      ctx.addIssue({
        path: ['relevantEducation'],
        code: z.ZodIssueCode.custom,
        message: 'custom.allStudyFields'
      })
    }

    const seenObjects = new Set(
      educations.map((obj) =>
        JSON.stringify({
          ...obj,
          educationFieldOfStudyId:
            obj.educationFieldOfStudyId ?? ALL_FIELD_OF_STUDY_ID
        })
      )
    )

    if (obj.educations.length > 1 && educations.length !== seenObjects.size) {
      ctx.addIssue({
        path: ['relevantEducation'],
        code: z.ZodIssueCode.custom,
        message: 'custom.noDuplicateEducation'
      })
    }

    if (obj.relevantEducation && !educations.length) {
      ctx.addIssue({
        path: ['relevantEducation'],
        code: z.ZodIssueCode.custom,
        message: 'custom.relevantEducation'
      })
    }
  }
)

export const advertLanguageValidator = z.object({
  languages: languageLevelValidator.array().min(1, 'custom.select1Language')
})
export const adStep2Validator = advertLanguageValidator.superRefine(
  (obj, ctx) => {
    const languages = obj.languages
      .filter((item) => !!item.language && !!item.languageLevel)
      .map((c) => c.language)

    if (!languages.length) {
      ctx.addIssue({
        path: ['languages'],
        code: z.ZodIssueCode.custom,
        message: 'custom.select1Language'
      })
    }

    // Do not use duplicate language
    const uniqueLanguages = new Set(languages)
    if (languages.length !== uniqueLanguages.size) {
      ctx.addIssue({
        path: ['languages'],
        code: z.ZodIssueCode.custom,
        message: 'custom.noDuplicateLangs'
      })
    }
  }
)

export const responsibilitiesItem = translationValidator.extend({
  value: z.string().max(400).optional()
})
export const advertResponsibilitiesValidator = z.object({
  responsibilities: responsibilitiesItem.array().min(1, 'custom.select1Resp')
})
export const adStep3Validator = advertResponsibilitiesValidator.superRefine(
  (obj, ctx) => {
    const responsibilities = obj.responsibilities
      .map((c) => c.value?.trim())
      .filter((item) => !!item)

    if (!responsibilities.length) {
      ctx.addIssue({
        path: ['responsibilities'],
        code: z.ZodIssueCode.custom,
        message: 'custom.required'
      })
    }

    // Do not use duplicate responsibility
    const uniqueResponsibilities = new Set(responsibilities)
    if (responsibilities.length !== uniqueResponsibilities.size) {
      ctx.addIssue({
        path: ['responsibilities'],
        code: z.ZodIssueCode.custom,
        message: 'custom.noDuplicateResps'
      })
    }
  }
)

export const advertDescriptionValidator = z.object({
  descriptions: adDescriptionValidator.array().min(0)
})
export const adStep4Validator = advertDescriptionValidator

export const advertCompanyProposalValidator = z.object({
  salaryFrom: z.number().int().min(100),
  salaryTo: z.number().int(),
  remoteWorkTypeId: z.string().uuid(),
  trainingBudgetId: z.string().uuid().nullable(),
  contractTypeId: z.string().uuid(),
  bonuses: z.string().array(),
  employmentStatus: z.nativeEnum(EmploymentStatus)
})
export const adStep5Validator = advertCompanyProposalValidator
  .passthrough()
  .superRefine((obj, ctx) => {
    if (!(obj.salaryTo >= obj.salaryFrom)) {
      ctx.addIssue({
        path: ['salaryTo'],
        code: z.ZodIssueCode.custom,
        message: 'custom.salary'
      })
    }
  })

export type IAdvertStep0 = z.infer<typeof adStep0Validator>
export type IAdvertStep1 = z.infer<typeof adStep1Validator>
export type IAdvertStep2 = z.infer<typeof adStep2Validator>
export type IAdvertStep3 = z.infer<typeof adStep3Validator>
export type IAdvertStep4 = z.infer<typeof adStep4Validator>
export type IAdvertStep5 = z.infer<typeof adStep5Validator>
